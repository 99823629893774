import { useEffect } from "react";
import { HeroSection } from "./heroSection";
import { HowItWorks } from "./howItWorks";
import { Intro } from "./intro";
import { RightFit } from "./rightFit";
import styles from "./styles.module.scss";
import { Testimonials } from "./testimonials";
import AOS from "aos";
import "aos/dist/aos.css";

const HomeUI = () => {
  useEffect((): any => {
    window.scrollTo(-0, -0);
    AOS.init({
      duration: 1000,
      offset: 100,
      easing: "ease-in-out",
    });
    AOS.refresh();
  }, []);

  return (
    <section className={styles.bg}>
      <HeroSection />
      <Intro />
      <RightFit />
      <HowItWorks />
      <Testimonials />
    </section>
  );
};
export { HomeUI };
